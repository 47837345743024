.padding-45 {
  padding:45px;
}
.padding-25tb {
  padding:25px 0 !important;
}
.padding-50tb {
  padding:50px 0 !important;
}
.padding-80b {
  padding:0 0 80px  !important;
}
.padding-75tb {
  padding:75px 0
}
.padding-90 {
  padding:90px;
  @media screen and (max-width: 993px) {
            padding:30px;
         }
}
