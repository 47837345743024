$theme-primary: #003471;
$theme-secondary: #00a3db;
$theme-tertiary: #FFAF00;
$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 75%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee
$white: #fff;
$secondary-light: lighten($theme-primary, 71.5%);
$secondary-lightdark: lighten($theme-primary, 68.5%);
$brand-twitter: #1da1f2;
$brand-facebook: #3b5998;
$brand-google-plus: #dd4b39;
$brand-instagram: #8a3ab9;
$brand-linkedin: #007bb6; // Global Components

body,
html {
    height: 100%;
    width: 100%;

    .scrollToTop {
        width: 10px;
        height: 10px;
        padding: 10px;
        text-align: center;
        background: whiteSmoke;
        font-weight: bold;
        color: #444;
        text-decoration: none;
        position: fixed;
        bottom: 15px;
        right: 15px;
        display: none;
        content: "Add-Icon";
    }

    .scrollToTop:hover {
        text-decoration: none;
    }
}

body {
    @include body-font;
}

a {
    @include transition-all;
    color: $theme-secondary;

    &:focus,
    &:hover {
        color: darken($theme-secondary, 10%);
    }
}

img {
    max-width: 100%;
    height: auto;
}

hr {
    max-width: 100px;
    margin: 25px auto 0;
    border-width: 1px;
    border-color: fade($gray-darker, 10%);
}

hr.light {
    border-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include heading-font;
}

h1 {
    font-size:50px;
    color:#333;
    font-weight: 300;
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.uppercase {
    text-transform: uppercase;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}


// Homepage Header

header {
    max-height: 90vh;
    padding:190px 0 100px;
    position: relative;
    background: url("../images/khs-banner.jpg") center center no-repeat;
    background-size: cover;
    @media (min-width: 768px) {}
    @media (min-width: 992px) {}

    .header-content {
        padding:25px;
        max-width:1600px;
        margin:0 auto;
        background: rgba(255,255,255,0.5);
        .slider-img {
            max-width:700px;
            float:right;
            margin-top: -135px;
            margin-right: 25px;
            border:5px solid #fff;
        }
        h1{
            color: #fff;
            font-size:50px;
            font-weight:300;
        }
        p {
            margin:15px 0;
            color: $white;
            font-size:20px;
        }
        ul {
            margin-left:35px;
            margin-bottom:15px;
            li {
                color: $white;
                font-size:20px;
            }
        }
    }




    .navbar {}

    .navbar.navbar {}
    // Navigation

    nav.navbar {
        position: fixed; //static
        top: 0;
        width: 100%;
        z-index: 9999;
        transition: all ease-in-out 0.2s;
        .top-right {
            img {
                max-width:30px;
                margin:2px;
            }
        }
        .navbar-brand img {
            max-width: 150px;
            transition: all ease-in-out 0.2s;
            //left:calc(50% - 100px);
            position: absolute;
            //padding:0;
            top:0px;
            left:25px;
        }

        .navbar-nav {
            margin-top: 18px;
            transition: all ease-in-out 0.2s;
        }

        .pre-header {
            width: 100%;
            background: $theme-primary;
            color:$white;

            //box-shadow: inset 0 11px 5px -10px #CCC, inset 0 -11px 15px -10px #CCC;
            transition: all ease-in-out 0.2s;
            .col-sm-4 {
                padding:10px 0
            }
        }

    }

    nav.navbar.sticky {
        background: $theme-primary;
        padding: 15px;

        .navbar-brand img {
            max-width: 100px;
            transition: all ease-in-out 0.2s;
            //left:calc(50% - 50px);
            //position: absolute;
            padding:0;
            top:10px;
        }

        .navbar-nav {
            margin-top: -5px;
        }

        .pre-header {
            margin-top: -40px;
            opacity: 0;
        }
    }

    .navbar-default {
        background-color: white;
        border-color: fade($gray-darker, 5%);
        @include transition-all;
        @include heading-font;

        .navbar-header .navbar-brand {
            @include heading-font;
            color: $theme-secondary;

            &:focus,
            &:hover {
                color: darken($theme-secondary, 10%);
            }
        }

        .navbar-header .navbar-toggle {
            font-size: 12px;
            color: $gray-darker;
            padding: 8px 10px;
        }

        .nav {
            > li {
                > a {
                    @include alt-font;
                    font-size: 16px;
                    display: block;
                }

                ,
                 > a,
                 > a:focus {
                    color: $gray-light;

                    &:hover {
                        color: $white;
                        background-color: rgba($theme-secondary, .5);
                    }
                }

                &.active {
                     > a:focus,
                    > a {
                        color: $theme-secondary !important;
                        background-color: transparent;

                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
        @media (min-width: 768px) {
            background-color: transparent;
            border-color: transparent;

            .navbar-header .navbar-brand {
                color: fade(white, 70%);

                &:focus,
                &:hover {
                    color: white;
                }
            }

            &.affix {
                background-color: white;
                border-color: fade($gray-darker, 10%);

                .navbar-header .navbar-brand {
                    color: $gray-darker;

                    &:focus,
                    &:hover {
                        color: $theme-secondary;
                    }
                }

                .nav > li > a,
                .nav > li > a:focus {
                    color: $gray-darker;

                    &:hover {
                        color: $theme-secondary;
                    }
                }
            }
        }
    }

    .sticky {
        .nav {
            > li {
                > a {
                    @include alt-font;
                    font-size: 16px;
                }

                 > a:focus,
                > a {
                    color: $gray-lighter;

                    &:hover {
                        color: lighten($theme-secondary, 35%);
                    }
                }

                &.active {
                     > a:focus,
                    > a {
                        color: $theme-primary !important;
                        background-color: transparent;

                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}
// Sections

section {}


// Footer
footer {


        ul.list-social {
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: inline-block;
                margin: 25px 5px 0;
                text-align: center;

                a {
                    display: block;
                    height: 50px;
                    width: 50px;
                    line-height: 50px;
                    font-size: 30px;
                    border-radius: 100%;
                    color: white;
                }

                &.twitter {
                    a {
                        background-color: $brand-twitter;

                        &:hover {
                            background-color: darken($brand-twitter, 5%);
                        }
                    }
                }

                &.facebook {
                    a {
                        background-color: $brand-facebook;

                        &:hover {
                            background-color: darken($brand-facebook, 5%);
                        }
                    }
                }

                &.google-plus {
                    a {
                        background-color: $brand-google-plus;

                        &:hover {
                            background-color: darken($brand-google-plus, 5%);
                        }
                    }
                }

                &.instagram {
                    a {
                        background-color: $brand-instagram;

                        &:hover {
                            background-color: darken($brand-instagram, 5%);
                        }
                    }
                }

                &.linkedin {
                    a {
                        background-color: $brand-linkedin;

                        &:hover {
                            background-color: darken($brand-linkedin, 5%);
                        }
                    }
                }
            }
        }
    }

    .copyright-bar {
        padding: 15px 0;
        background: $theme-primary;
        color: #fff;

        p {
            margin: 5px 0;
            line-height: 2em;
        }
}
// Bootstrap Overrides

.bg-primary {
    background: $theme-secondary;
    background: -webkit-linear-gradient($theme-secondary , $theme-secondary , lighten($theme-secondary, 15%)); //transparentize($theme-secondary, 0.7)
    background: linear-gradient($theme-secondary , $theme-secondary , lighten($theme-secondary, 15%)); //transparentize($theme-secondary, 0.7)
}

.text-primary {
    color: $theme-secondary;
}

.no-gutter, .no-gutter > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}
// Button Styles

.btn-outline {
    color: white;
    border-color: white;
    border: 1px solid;

    &.active,
    &:active,
    &:focus,
    &:hover {
        color: white;
        background-color: $theme-secondary;
        border-color: $theme-secondary;
    }
}

.btn {
    @include alt-font;
    border-radius: 300px;
}

.btn-xl {
    padding: 15px 45px;
    font-size: 11px;
}
// Extras
// -- Highlight Color Customization

::-moz-selection {
    color: white;
    text-shadow: none;
    background: $gray-darker;
}

::selection {
    color: white;
    text-shadow: none;
    background: $gray-darker;
}

img::selection {
    color: white;
    background: transparent;
}

img::-moz-selection {
    color: white;
    background: transparent;
}

body {
    webkit-tap-highlight-color: $gray-darker;
}
